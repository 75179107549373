$primary-color: #276ef1;
$primary-lighten-color: lighten($primary-color, 5%);
$primary-darken-color: darken($primary-color, 5%);

$primary-background-color: #f6f6f6;

$grey-100-color: #f3f6f9;
$grey-500-color: #a0aab4;

$error-color: #d44333;
$warning-color: #f18827;
$success-color: #82cf6f;

$small-font-size: 14px;
$default-font-size: 16px;
$large-font-size: 18px;

$primary-border-radius: 12px;
$secondary-border-radius: 8px;
