@import "@shared/ui/variables";
@import "@shared/ui/mixins";

.auth_form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;

  &__info {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;

    &__alert {
      background-color: #ffb54766;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem;
      border-radius: $secondary-border-radius;
      width: 100%;

      &__message {
        color: $error-color;
      }
    }
  }

  &__decorator_button {
    background-color: none;
    border: none;
    margin: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    overflow: hidden;
  }

  &__button {
    background-color: $primary-color;
    color: white;
    border: none;
    border-radius: $primary-border-radius;
    font-size: 20px !important;
    font-weight: 600;
    padding: 1rem !important;
  }
}
