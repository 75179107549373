@import "@shared/ui/variables";

.auth_card {
  background-color: white;
  border-radius: $primary-border-radius;
  padding: 40px 1rem 20px;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  height: 500px;
  display: flex;
  flex-direction: column;

  &__title {
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
