@import "./variables";

body {
  margin: 0;
  font-family: Arial, sans-serif;
  position: fixed;
  inset: 0;
  background: linear-gradient(
      332deg,
      rgba(6, 15, 91, 0.4) 0%,
      rgba(4, 11, 71, 0) 100%
    ),
    #276ef1;

  &.authorized {
    background-color: $primary-background-color;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
}

#app {
  height: 100%;
  width: 100%;
}
